import store from '../store';
import Vue from 'vue';
import { USERS_SET_TOKEN_FCM } from '../store/types/actions.types';
import storage from './storage';

export default (function () {
  const firebaseConfig = {
    apiKey: 'AIzaSyCdqVcoLo79Jr5Qkt-ow1l76iKbk9rrs4A',
    authDomain: 'call-center-8af90.firebaseapp.com',
    projectId: 'call-center-8af90',
    storageBucket: 'call-center-8af90.appspot.com',
    messagingSenderId: '681627837010',
    appId: '1:681627837010:web:32e1b0dfbc9336993e358b'
  };

  let isNotLoad = false;

  return () => {
    if (isNotLoad) {
      return;
    }
    isNotLoad = true;
    Promise.resolve()
      .then(() =>
        Vue.loadScript(
          'https://www.gstatic.com/firebasejs/8.3.1/firebase-app.js'
        )
      )
      .then(() =>
        Vue.loadScript(
          'https://www.gstatic.com/firebasejs/8.3.1/firebase-messaging.js'
        )
      )
      .then(() =>
        Vue.loadScript(
          'https://www.gstatic.com/firebasejs/8.3.1/firebase-analytics.js'
        )
      )
      .then(async () => {
        window.firebase.initializeApp(firebaseConfig);
        const messaging = window.firebase.messaging();
        const permission = await Notification.requestPermission();
        const { token: tokenReadyStore, id: userId } = storage.get('fcm_token_obj') ?? {};
        const token = await messaging.getToken();
        if (permission === 'granted' && (tokenReadyStore !== token || store.getters['auth/currentUser']?.id !== userId)) {
          try {
            await store.dispatch(`user/${USERS_SET_TOKEN_FCM}`, {
              params: {
                token
              }
            });
            storage.set('fcm_token_obj', { token, id: store.getters['auth/currentUser']?.id });
          } catch (e) {
            console.log(e);
          }
        } else {
          console.log('Unable to get permission to notify.');
        }

        const play = () => {
          /*      try {
              const context = new AudioContext();
              window
                .fetch(soundUrl)
                .then(response => response.arrayBuffer())
                .then(arrayBuffer => context.decodeAudioData(arrayBuffer))
                .then(audioBuffer => {
                  const source = context.createBufferSource();
                  source.buffer = audioBuffer;
                  source.connect(context.destination);
                  source.start();
                });
            } catch (ex) {
              console.log(ex);
            } */
        };

        try {
          const broadcast = new BroadcastChannel('play');
          broadcast.onmessage = play;
        } catch (ex) {
          console.log(ex);
        }

        messaging.onMessage(function (payload) {
          setTimeout(() => {
            play();
            navigator.serviceWorker.register('/firebase-messaging-sw.js');
            Notification.requestPermission(function (result) {
              if (result === 'granted') {
                navigator.serviceWorker.ready
                  .then(function (registration) {
                    const data = { ...payload.notification, ...payload.data };
                    const notificationTitle = data.title;
                    const notificationOptions = {
                      body: data.body,
                      icon: data.icon,
                      image: data.image,
                      badge: data.badge,
                      requireInteraction: true,
                      click_action: data.click_action,
                      data
                    };
                    return registration.showNotification(
                      notificationTitle,
                      notificationOptions
                    );
                  })
                  .catch(function (error) {
                    console.log('ServiceWorker registration failed', error);
                  });
              }
            });
          }, 100);
        });
      });
  };
}());

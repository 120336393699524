import get from 'lodash/get';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';

export default {
  clearObject (obj) {
    return obj instanceof Object ? Object.entries(obj).reduce((item, [key, value]) => {
      if (!this.isEmpty(value)) {
        item[key] = this.clearObject(value);
      }
      return item;
    }, obj instanceof Array ? [] : {}) : obj;
  },
  typeOf (obj) {
    const map = {
      '[object Boolean]': 'boolean',
      '[object Number]': 'number',
      '[object String]': 'string',
      '[object Function]': 'function',
      '[object Array]': 'array',
      '[object Date]': 'date',
      '[object RegExp]': 'regExp',
      '[object Undefined]': 'undefined',
      '[object Null]': 'null',
      '[object Object]': 'object'
    };
    return map[Object.prototype.toString.call(obj)];
  },
  isEmpty (obj, path = null) {
    if (this.typeOf(path) !== 'null') {
      obj = this.get(obj, path);
    }

    switch (this.typeOf(obj)) {
      case 'null':
      case 'undefined':
        return true;
      case 'object':
        return Object.keys(obj).length === 0;
      case 'array':
      case 'string':
        return obj.length === 0;
      case 'number':
        // eslint-disable-next-line no-self-compare
        return obj !== obj || !isFinite(obj);
    }
    return false;
  },
  getOneArray (data) {
    if (this.typeOf(data) === 'array' && data.length > 0) {
      return data.filter(x => x)[0];
    }
    return null;
  },
  get,
  clone,
  cloneDeep
};
export const getDateCommon = (_date, seconds = 0) => {
  if (_date) {
    _date = new Date(_date);
  } else {
    _date = new Date();
  }
  return Date.UTC(_date.getUTCFullYear(), _date.getUTCMonth(), _date.getUTCDate(), _date.getUTCHours(), _date.getUTCMinutes(), seconds, 0);
};

export const tabelCell = (() => {
  let countCell = Math.round((window.innerHeight - 238) / 40);
  if (countCell % 5 > 3) {
    countCell = countCell + (5 - (countCell % 5));
  } else {
    countCell = countCell - (4 - (countCell % 5));
  }
  return () => {
    return countCell < 10 ? 10 : countCell;
  };
})();

export const offsetUTCinHours = () => {
  const offset = new Date().getTimezoneOffset() * -1;
  return offset / 60;
};

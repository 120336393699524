import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './filters';
import vSelect from 'vue-select';
import './sass/fonts.sass';
import VueCookies from 'vue-cookies';
// eslint-disable-next-line no-unused-vars
import { CHECK_AUTH, USER_ME, USERS_SET_TOKEN_FCM } from './store/types/actions.types';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import NProgress from 'nprogress';
import './install/fix-date';
import { ID_ROLE_OPERATOR, ID_ROLE_TEAM_LEADER } from './service/consts';
import LoadScript from 'vue-plugin-load-script';
import fcm from './service/fcm';

const mobile = () => import('./install/mobile');
const desktop = () => import('./install/desktop');

if (Vue.$device.mobile) {
  mobile();
} else {
  desktop();
}

Vue.component('VSelect', vSelect);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.use(VueCookies);
Vue.use(LoadScript);

Vue.config.productionTip = false;

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (to?.name === 'Login') {
    try {
      await store.dispatch(`auth/${CHECK_AUTH}`, {
        context: Vue
      });
      next('/users');
    } catch (e) {
      next();
      return;
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      await store.dispatch(`auth/${CHECK_AUTH}`, {
        context: Vue
      });
    } catch (e) {
      next('/login');
      return;
    }

    if (store.getters['auth/isAuthenticated']) {
      await store.dispatch(`auth/${USER_ME}`);
      if (
        Vue.$device.mobile ||
        store.getters['auth/isAdmin'] ||
        (to?.meta?.role || []).some(role => (role.type === ID_ROLE_TEAM_LEADER && store.getters['auth/isModerator'] && role.allow) ||
          (role.type === ID_ROLE_OPERATOR && store.getters['auth/isOperator'] && role.allow))
      ) {
        next();
      } else {
        if (store.getters['auth/isOperator']) {
          next('/operator');
        } else {
          next('/users');
        }
      }
      fcm();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
    .then((reg) => {
      // регистрация сработала
      console.log('Registration succeeded. Scope is ' + reg);
    }).catch((error) => {
    // регистрация прошла неудачно
      console.log('Registration failed with ' + error);
    });
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
